import React, { useEffect, useState } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import "./FormStyles.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getStreetAddress,
  getUser,
  getUserType,
} from "../../Helpers/Utils/Common";
import swoosh from "../../Assets/Logo/swoosh.png";
import {
  validateClaasificationDetails,
  validatePrimaryDetails,
} from "../../Helpers/Validation/FormValidation";
import InputError from "../../Components/InputError/InputError";

function Registration({
  navigation,
  details,
  setDetails,
  primaryDetails,
  setPrimaryDetails,
  classificationDetails,
  setClassificationDetails,
  accounts,
  setAccounts,
}) {
  const user_type = getUserType();
  const [isError, setIsError] = useState({
    classification_type: false,
    activation_type: false,
    anc_ans_aps: false, //ANC-ANS-APS name
    location_name: false, //Clinic/Hospital
    remarks: false, //DR NAME,COMPETITORS ACTIVITY
    location_image: false,
  });

  function handleDetailsChange(e) {
    const { name, value } = e.target;
    setPrimaryDetails({ ...primaryDetails, [name]: value });
    // setClassificationDetails({...classificationDetails, [name]:value})
  }
  // function handleClassificationDetailsChange(e){
  //     const {name, value} = e.target
  //     setClassificationDetails({...classificationDetails, [name]:value})
  // }

  function handleSubmit() {
    if (validatePrimaryDetails(primaryDetails, setIsError)) {
      navigation.next();
    }
  }

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-md-center p-5 mt-10">
        <Form.Label className="title">
          {user_type.toLowerCase() === "patient" ? "Patient" : "Consumer"}{" "}
          Registration Form{" "}
        </Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Group className="mb-3" controlId="formClassification">
            <Form.Label className="font-med">
              Classification Type <span className="required-badge">*</span>
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-activation_type-${type}`} className="mb-3">
                {user_type.toLowerCase() === "patient" && (
                  <>
                    <Form.Check
                      inline
                      label="ANC-FULLBOOTH"
                      value="ANC-FULLBOOTH"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-1`}
                      onClick={handleDetailsChange}
                    />
                    <Form.Check
                      inline
                      label="ANC"
                      value="ANC"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-2`}
                      onClick={handleDetailsChange}
                    />
                  </>
                )}
                {user_type.toLowerCase() === "consumer" && (
                  <>
                    <Form.Check
                      inline
                      label="ANS-FULLBOOTH"
                      value="ANS-FULLBOOTH"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-2`}
                      onClick={handleDetailsChange}
                    />
                    <Form.Check
                      inline
                      label="ANS"
                      value="ANS"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-3`}
                      onClick={handleDetailsChange}
                    />
                    <Form.Check
                      inline
                      label="APS"
                      value="APS"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-4`}
                      onClick={handleDetailsChange}
                    />
                  </>
                )}
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">
              Activation Type <span className="required-badge">*</span>
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-activation_type-${type}`} className="mb-3">
                {user_type.toLowerCase() === "patient" && (
                  <>
                    <Form.Check
                      inline
                      label="IN-CLINIC"
                      value="IN-CLINIC"
                      name="activation_type"
                      type={type}
                      id={`inline-activation_type-${type}-3`}
                      onClick={handleDetailsChange}
                    />
                    <Form.Check
                      inline
                      label="IN-STORE"
                      value="IN-STORE"
                      name="activation_type"
                      type={type}
                      id={`inline-activation_type-${type}-4`}
                      onClick={handleDetailsChange}
                    />
                    <Form.Check
                      inline
                      label="WATSONS"
                      value="WATSONS"
                      name="activation_type"
                      type={type}
                      id={`inline-activation_type-${type}-5`}
                      onClick={handleDetailsChange}
                    />
                  </>
                )}
                {user_type.toLowerCase() === "consumer" &&
                  primaryDetails.classification_type === "ANS-FULLBOOTH" && (
                    <>
                      <Form.Check
                        inline
                        label="NKA"
                        value="NKA"
                        name="activation_type"
                        type={type}
                        id={`inline-activation_type-${type}-6`}
                        onClick={handleDetailsChange}
                      />
                      <Form.Check
                        inline
                        name="activation_type"
                        label="LKA"
                        value="LKA"
                        type={type}
                        id={`inline-activation_type-${type}-7`}
                        onClick={handleDetailsChange}
                      />
                      <Form.Check
                        inline
                        name="activation_type"
                        label="OFF-STORE"
                        value="OFF-STORE"
                        type={type}
                        id={`inline-activation_type-${type}-2`}
                        onClick={handleDetailsChange}
                      />
                    </>
                  )}
                {user_type.toLowerCase() === "consumer" &&
                  primaryDetails.classification_type === "ANS" && (
                    <>
                      <Form.Check
                        inline
                        label="NKA"
                        value="NKA"
                        name="activation_type"
                        type={type}
                        id={`inline-activation_type-${type}-6`}
                        onClick={handleDetailsChange}
                      />
                      <Form.Check
                        inline
                        name="activation_type"
                        label="LKA"
                        value="LKA"
                        type={type}
                        id={`inline-activation_type-${type}-7`}
                        onClick={handleDetailsChange}
                      />
                    </>
                  )}
                {user_type.toLowerCase() === "consumer" &&
                  primaryDetails.classification_type === "APS" && (
                    <>
                      <Form.Check
                        inline
                        label="IN-STORE"
                        value="IN-STORE"
                        name="activation_type"
                        type={type}
                        id={`inline-activation_type-${type}-9`}
                        onClick={handleDetailsChange}
                      />
                      <Form.Check
                        inline
                        name="activation_type"
                        label="OFF-STORE"
                        value="OFF-STORE"
                        type={type}
                        id={`inline-activation_type-${type}-10`}
                        onClick={handleDetailsChange}
                      />
                    </>
                  )}
              </div>
            ))}
            <InputError
              isValid={isError.activation_type}
              message={"This Field is Required*."}
            />
          </Form.Group>
          {/* {(primaryDetails.activation_type === "NKA" ||
            primaryDetails.activation_type === "LKA") && (
            <>
              
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">
                  Date <span className="required-badge">*</span>
                </Form.Label>
                <Form.Control
                  name="date"
                  type="date"
                  defaultValue={primaryDetails.date}
                  onChange={handleDetailsChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">
                  {primaryDetails.activation_type === "IN-STORE"
                    ? "ANS Name"
                    : "APS Name"}{" "}
                  <span className="required-badge">*</span>
                </Form.Label>
                <Form.Control
                  name="anc_ans_aps"
                  type="text"
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.anc_ans_aps}
                  message={"This Field is Required*."}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">
                  Store/Venue <span className="required-badge">*</span>
                </Form.Label>
                <Form.Control
                  name="location_name"
                  type="text"
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.location_name}
                  message={"This Field is Required*."}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">
                  Competitor's Activity{" "}
                  <span className="required-badge">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="remarks"
                  onChange={handleDetailsChange}
                />
                <InputError
                  isValid={isError.remarks}
                  message={"This Field is Required*."}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreImage">
                <Form.Label className="font-med">
                  Image <span className="required-badge">*</span>
                </Form.Label>
                <Form.Control
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  name="location_image"
                  onChange={(e) =>
                    setPrimaryDetails({
                      ...primaryDetails,
                      location_image: e.target.files,
                    })
                  }
                />
                <InputError
                  isValid={isError.location_image}
                  message={"This Field is Required*."}
                />
              </Form.Group>
            </>
          )} */}
          {primaryDetails.classification_type !== "" &&
            primaryDetails.activation_type !== "" &&
            user_type.toLowerCase() === "patient" && (
              <>
                {/* <Form.Label className='title' style={{textAlign:"center"}}>Patient Registration Form <span className='required-badge'>*</span></Form.Label> */}
                <Form.Group className="mb-3" controlId="patientDate">
                  <Form.Label className="font-med">
                    Date <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    name="date"
                    type="date"
                    defaultValue={primaryDetails.date}
                    onChange={handleDetailsChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="patientANC">
                  <Form.Label className="font-med">
                    ANC Name <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    name="anc_ans_aps"
                    type="text"
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.anc_ans_aps}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="patientClinic">
                  <Form.Label className="font-med">
                    Clinic/Hospital/Store{" "}
                    <span className="required-badge">*</span>
                  </Form.Label>
                  {/* <Form.Control
                    name="location_name"
                    type="text"
                    onChange={handleDetailsChange}
                  /> */}
                  <Form.Select
                    name="location_name"
                    type="dropdown"
                    onChange={handleDetailsChange}
                  >
                    <option>Select</option>
                    {accounts.map((data) => {
                      return <option>{data.account_name}</option>;
                    })}
                  </Form.Select>
                  <InputError
                    isValid={isError.location_name}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="patientDoctor">
                  <Form.Label className="font-med">
                    Name of Doctor/HCP <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="remarks"
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.remarks}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="patientImage">
                  <Form.Label className="font-med">
                    Image <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="location_image"
                    onChange={(e) =>
                      setPrimaryDetails({
                        ...primaryDetails,
                        location_image: e.target.files,
                      })
                    }
                  />
                  <InputError
                    isValid={isError.location_image}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
              </>
            )}
          {primaryDetails.classification_type !== "" &&
            primaryDetails.activation_type !== "" &&
            user_type.toLowerCase() === "consumer" && (
              <>
                <Form.Group className="mb-3" controlId="consumerDate">
                  <Form.Label className="font-med">
                    Date <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    name="date"
                    type="date"
                    defaultValue={primaryDetails.date}
                    onChange={handleDetailsChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="consumerAPS">
                  <Form.Label className="font-med">
                    APS/ANS Name <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    name="anc_ans_aps"
                    type="text"
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.anc_ans_aps}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="consumerLocation">
                  <Form.Label className="font-med">
                    Store/Venue <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Select
                    name="location_name"
                    type="dropdown"
                    onChange={handleDetailsChange}
                  >
                    <option>Select</option>
                    {accounts.map((data) => {
                      return <option>{data.account_name}</option>;
                    })}
                  </Form.Select>
                  {/* <Form.Control
                    name="location_name"
                    type="text"
                    onChange={handleDetailsChange}
                  /> */}
                  <InputError
                    isValid={isError.location_name}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="consumerCompetitor">
                  <Form.Label className="font-med">
                    Competitor's Activity{" "}
                    <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="remarks"
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.remarks}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="consumerImage">
                  <Form.Label className="font-med">
                    Image <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="location_image"
                    onChange={(e) =>
                      setPrimaryDetails({
                        ...primaryDetails,
                        location_image: e.target.files,
                      })
                    }
                  />
                  <InputError
                    isValid={isError.location_image}
                    message={"This Field is Required*."}
                  />
                </Form.Group>
              </>
            )}
          {(primaryDetails.activation_type !== "" ||
            primaryDetails.classification_type) && (
            <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn"
                onClick={handleSubmit}
              >
                Next
              </Button>
            </Form.Group>
          )}
        </Form>
        {/* <Col xs={12} md={12} className="mt-3">
            <img src={diabetasol} alt="logo" width={130} height={60} style={{borderRadius:"10px"}}/>
            {" "}
            <img src={entrasol} alt="logo" width={130} height={60} style={{borderRadius:"10px"}}/>
          </Col>
      <Col xs={12} md={12} className="mt-1">
            <img src={logo} alt="logo" width={100} height={50} style={{borderRadius:"10px"}}/>
            
          </Col> */}
      </Row>
      <img src={swoosh} alt="logo" height={250} className={"swoosh"} />
    </Container>
  );
}

export default Registration;
