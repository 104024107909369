import React, { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import Registration from "./Registration";
import Consumer from "./Consumer";
import { useParams } from "react-router-dom";
import { getTime, getTime12Hour } from "../../Helpers/Utils/Common";
import Summary from "./Summary";
import {
  getAccounts,
  getFreebies,
  getPromos,
} from "../../Helpers/API/formsAPI";

const steps = [{ id: "registration" }, { id: "consumer" }, { id: "summary" }];

function SwitchForm() {
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const [addCustomer, setAddCustomer] = useState(false);
  const [formId, setFormId] = useState("");
  const [primaryDetails, setPrimaryDetails] = useState({
    classification_type: "",
    activation_type: "",
    date: new Date().toISOString().split("T")[0],
    anc_ans_aps: "", //ANC-ANS-APS name
    location_name: "", //Clinic/Hospital
    remarks: "", //DR NAME,COMPETITORS ACTIVITY
    location_image: "",
  });
  // const [classificationDetails, setClassificationDetails] = useState({
  //     classification_types:"",
  //     date:new Date().toLocaleDateString("en-CA"),
  //     anc_ans_aps:"",//ANC-ANS-APS name
  //     location_name:"",//Clinic/Hospital
  //     remarks:"",//DR NAME,COMPETITORS ACTIVITY
  //     location_image:"",
  // })
  const [details, setDetails] = useState({
    //CUSTOMER DETAILS
    name: "",
    contact_no: "",
    email: "",
    // purchased_products:[],
    // promotions:[],
    // promotion_qties:[],
    // freebies:[],
    // freebies_qties:[],
    call_time: "",

    // call_time:getTime12Hour(new Date()),
    bumo: "", //FOR INSTORE/OFFSTORE
    patient_type: "", //FOR INCLINIC
    lc_nc_p: "",
    eu_cg: "",
    or_no: "",
    is_diabetasol: "0",
    is_entrasol: "0",
    is_diabetasol_sweetener: "0",
    diabetasol_diagnosis: "",
    entrasol_diagnosis: "",
    diabetasol_sweetener_diagnosis: "",
  });

  const [checked, setChecked] = useState(false);
  const [checkedDiabetasol, setCheckedDiabetasol] = useState(false);
  const [checkedEntrasol, setCheckedEntrasol] = useState(false);
  const [checkedDiabetasolSweetener, setCheckedDiabetasolSweetener] =
    useState(false);

  const [promoList, setPromoList] = useState([]);
  // const [promoList, setPromoList] = useState([
  //   { name: "DIABETASOL 180g LESS40PHP", qty: 0 },
  //   { name: "DIABETASOL 600g FREEQUAKEROATS", qty: 0 },
  //   { name: "DIABETASOL 600g LESS75PHP", qty: 0 },
  //   { name: "DIABETASOL 800g LESS150PHP", qty: 0 },

  //   { name: "ENTRASOL 200g LESS40PHP", qty: 0 },
  //   { name: "ENTRASOL 600g LESS100PHP", qty: 0 },
  // ]);

  const [promoListLKA, setPromoListLKA] = useState([
    { name: "ENTRASOL 180g LESS40PHP", qty: 0 },
    { name: "ENTRASOL 600g FREEQUAKEROATS", qty: 0 },
    { name: "DIABETASOL 600g LESS75PHP", qty: 0 },
    { name: "DIABETASOL 800g LESS150PHP", qty: 0 },

    { name: "DIABETASOL 200g LESS40PHP", qty: 0 },
    { name: "ENTRASOL 600g LESS100PHP", qty: 0 },
  ]);

  const [freebies, setFreebies] = useState([]);
  // const [freebies, setFreebies] = useState([
  //   { name: "DIABETASOL", qty: 0 },
  //   { name: "DIABETASOL SWEETENER", qty: 0 },
  //   { name: "ENTRASOL", qty: 0 },
  // ]);
  const [productCodes, setProductCodes] = useState([
    { name: "DIABETASOL SWEET 50x1g", qty: 0 },

    { name: "DIABETASOL-VAN 180g", qty: 0 },
    { name: "DIABETASOL-VAN 360g", qty: 0 },
    { name: "DIABETASOL-VAN 600g", qty: 0 },
    { name: "DIABETASOL-VAN 800g", qty: 0 },
    { name: "DIABETASOL-VAN 1kg", qty: 0 },

    { name: "DIABETASOL-CHO 180g", qty: 0 },
    { name: "DIABETASOL-CHO 360g", qty: 0 },
    { name: "DIABETASOL-CHO 600g", qty: 0 },

    { name: "DIABETASOL-CAP 180g", qty: 0 },
    { name: "DIABETASOL-CAP 600g", qty: 0 },

    { name: "ENTRASOL-VAN 200g", qty: 0 },
    { name: "ENTRASOL-VAN 600g", qty: 0 },

    { name: "ENTRASOL-CHO 200g", qty: 0 },
    { name: "ENTRASOL-CHO 600g", qty: 0 },
  ]);
  const [accounts, setAccounts] = useState([]);

  async function fetchAccounts() {
    const response = await getAccounts();
    if (response.data) {
      setAccounts(response.data);
    }
  }
  async function fetchFreebies() {
    const response = await getFreebies();
    if (response.data) {
      var arr = [];
      response.data.map((data) => {
        arr.push({ ...data, qty: 0 });
      });
      setFreebies(arr);
    }
  }

  async function fetchPromos() {
    const response = await getPromos(
      primaryDetails.activation_type,
      primaryDetails.classification_type
    );
    if (response.data) {
      var arr = [];
      response.data.map((data) => {
        arr.push({ ...data, qty: 0 });
      });
      setPromoList(arr);
    }
  }

  useEffect(() => {
    fetchFreebies();
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (
      primaryDetails.classification_type !== "" &&
      primaryDetails.activation_type !== ""
    ) {
      fetchPromos();
    }
  }, [primaryDetails.classification_type]);
  useEffect(() => {
    if (
      primaryDetails.classification_type !== "" &&
      primaryDetails.activation_type !== ""
    ) {
      fetchPromos();
    }
  }, [primaryDetails.activation_type]);

  const registrationProps = {
    navigation,
    details,
    setDetails,
    primaryDetails,
    setPrimaryDetails,
    accounts,
    setAccounts,
  };
  const consumerProps = {
    navigation,
    details,
    setDetails,
    primaryDetails,
    productCodes,
    setProductCodes,
    freebies,
    setFreebies,
    promoList,
    setPromoList,
    promoListLKA,
    setPromoListLKA,
    checked,
    setChecked,
    checkedDiabetasol,
    setCheckedDiabetasol,
    checkedEntrasol,
    setCheckedEntrasol,
    checkedDiabetasolSweetener,
    setCheckedDiabetasolSweetener,
  };
  const summaryProps = {
    navigation,
    details,
    primaryDetails,
    productCodes,
    freebies,
    promoList,
    promoListLKA,
    addCustomer,
    setAddCustomer,
    formId,
    setFormId,
    checked,
    setChecked,
    checkedDiabetasol,
    setCheckedDiabetasol,
    checkedEntrasol,
    setCheckedEntrasol,
    checkedDiabetasolSweetener,
    setCheckedDiabetasolSweetener,
  };
  switch (step.id) {
    case "registration":
      return <Registration {...registrationProps} />;
    case "consumer":
      return <Consumer {...consumerProps} />;
    case "summary":
      return <Summary {...summaryProps} />;
  }

  return <div></div>;
}

export default SwitchForm;
