import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Card,
  ListGroup,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import {
  getUserType,
  refreshPage,
  removeUserSession,
} from "../../Helpers/Utils/Common";
import { createCustomer, createInfo } from "../../Helpers/API/formsAPI";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoutUser } from "../../Helpers/API/authApi";
import logo from "../../Assets/Logo/kalbe.png";
import entrasol from "../../Assets/Logo/entrasol.png";
import diabetasol from "../../Assets/Logo/diabetasol.png";
import swoosh from "../../Assets/Logo/swoosh.png";

function Summary({
  navigation,
  details,
  promoListLKA,
  primaryDetails,
  productCodes,
  freebies,
  promoList,
  addCustomer,
  setAddCustomer,
  formId,
  setFormId,
  checked,
  setChecked,
  checkedDiabetasol,
  setCheckedDiabetasol,
  checkedEntrasol,
  setCheckedEntrasol,
  checkedDiabetasolSweetener,
  setCheckedDiabetasolSweetener,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const user_type = getUserType();
  const [showBack, setShowBack] = useState(false);

  async function addInformation() {
    if (addCustomer) {
      const response = await createCustomer(
        formId,
        details,
        productCodes,
        freebies,
        promoList
      );

      if (response.data) {
        toast.success("Information Successfully Saved", {
          autoClose: 4000,
          hideProgressBar: true,
          theme: "colored",
        });
        setOpenModal(true);
        setLoading(false);
      } else {
        toast.error("Failed to Save Information", {
          autoClose: 4000,
          hideProgressBar: true,
          theme: "colored",
        });
        setLoading(false);
      }
    } else {
      const response = await createInfo(
        details,
        primaryDetails,
        productCodes,
        freebies,
        promoList
      );

      if (response.data) {
        setFormId(response.data.form_id);
        toast.success("Information Successfully Saved", {
          autoClose: 4000,
          hideProgressBar: true,
          theme: "colored",
        });
        setOpenModal(true);
        setLoading(false);
      } else {
        toast.error("Failed to Save Information", {
          autoClose: 4000,
          hideProgressBar: true,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  }

  async function handleLogout() {
    removeUserSession();
    navigate("/");
  }

  function handleSubmit() {
    setLoading(true);
    addInformation();
  }

  function handleAnotherRegistration() {
    for (const [key, value] of Object.entries(details)) {
      if (
        key === "is_diabetasol" ||
        key === "is_entrasol" ||
        key === "is_diabetasol_sweetener"
      ) {
        details[key] = "0";
      } else {
        details[key] = "";
      }
    }
    productCodes.map((data) => {
      data.qty = 0;
    });
    promoList.map((data) => {
      data.qty = 0;
    });
    promoListLKA.map((data) => {
      data.qty = 0;
    });
    freebies.map((data) => {
      data.qty = 0;
    });
    setCheckedDiabetasol(false);
    setCheckedDiabetasolSweetener(false);
    setCheckedEntrasol(false);
    setChecked(false);
    setAddCustomer(true);
    navigation.go("consumer");
    window.scrollTo(0, 0);
    setOpenModal(false);
  }

  function handleAnotherStore() {
    for (const [key, value] of Object.entries(details)) {
      details[key] = "";
    }

    // setprimaryDetails.Activation_type("")
    navigation.go("registration");
    setOpenModal(false);
  }

  function handleBack() {
    setShowBack(true);
  }

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-md-center p-5 mt-10">
        <Form.Label className="summary">SUMMARY</Form.Label>
        <hr />
        <Form.Label className="subtitle">Store Details</Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">Classification Type</Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                {user_type.toLowerCase() === "patient" && (
                  <>
                    <Form.Check
                      inline
                      disabled
                      label="ANC-FULLBOOTH"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-1`}
                      checked={
                        primaryDetails.classification_type === "ANC-FULLBOOTH"
                      }
                    />
                    <Form.Check
                      inline
                      disabled
                      label="ANC"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-1`}
                      checked={primaryDetails.classification_type === "ANC"}
                    />
                  </>
                )}
                {user_type.toLowerCase() === "consumer" && (
                  <>
                    <Form.Check
                      inline
                      disabled
                      label="ANS-FULLBOOTH"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-1`}
                      checked={
                        primaryDetails.classification_type === "ANS-FULLBOOTH"
                      }
                    />
                    <Form.Check
                      inline
                      disabled
                      label="ANS"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-3`}
                      checked={primaryDetails.classification_type === "ANS"}
                    />
                    <Form.Check
                      inline
                      disabled
                      label="APS"
                      name="classification_type"
                      type={type}
                      id={`inline-classification_type-${type}-4`}
                      checked={primaryDetails.classification_type === "APS"}
                    />
                  </>
                )}
              </div>
            ))}
            <Form.Label className="font-med">Activation Type</Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                {user_type.toLowerCase() === "patient" && (
                  <>
                    <Form.Check
                      inline
                      disabled
                      label="IN-CLINIC"
                      name="activation_type"
                      type={type}
                      id={`inline-activation_type-${type}-1`}
                      checked={primaryDetails.activation_type === "IN-CLINIC"}
                    />
                    <Form.Check
                      inline
                      disabled
                      label="IN-STORE"
                      name="activation_type"
                      type={type}
                      id={`inline-activation_type-${type}-2`}
                      checked={primaryDetails.activation_type === "IN-STORE"}
                    />
                    <Form.Check
                      inline
                      disabled
                      label="WATSONS"
                      name="activation_type"
                      type={type}
                      id={`inline-activation_type-${type}-2`}
                      checked={primaryDetails.activation_type === "WATSONS"}
                    />
                  </>
                )}
                {user_type.toLowerCase() === "consumer" &&
                  primaryDetails.classification_type === "ANS-FULLBOOTH" && (
                    <>
                      <Form.Check
                        inline
                        disabled
                        label="NKA"
                        name="activation_type"
                        type={type}
                        id={`inline-activation_type-${type}-4`}
                        checked={primaryDetails.activation_type === "NKA"}
                      />
                      <Form.Check
                        inline
                        disabled
                        name="activation_type"
                        label="LKA"
                        type={type}
                        id={`inline-activation_type-${type}-5`}
                        checked={primaryDetails.activation_type === "LKA"}
                      />
                      <Form.Check
                        inline
                        disabled
                        name="activation_type"
                        label="OFF-STORE"
                        type={type}
                        id={`inline-activation_type-${type}-5`}
                        checked={primaryDetails.activation_type === "OFF-STORE"}
                      />
                    </>
                  )}
                {user_type.toLowerCase() === "consumer" &&
                  primaryDetails.classification_type === "ANS" && (
                    <>
                      <Form.Check
                        inline
                        disabled
                        label="NKA"
                        name="activation_type"
                        type={type}
                        id={`inline-activation_type-${type}-4`}
                        checked={primaryDetails.activation_type === "NKA"}
                      />
                      <Form.Check
                        inline
                        disabled
                        name="activation_type"
                        label="LKA"
                        type={type}
                        id={`inline-activation_type-${type}-5`}
                        checked={primaryDetails.activation_type === "LKA"}
                      />
                    </>
                  )}
                {user_type.toLowerCase() === "consumer" &&
                  primaryDetails.classification_type === "APS" && (
                    <>
                      <Form.Check
                        inline
                        disabled
                        label="IN-STORE"
                        name="activation_type"
                        type={type}
                        id={`inline-activation_type-${type}-4`}
                        checked={primaryDetails.activation_type === "IN-STORE"}
                      />
                      <Form.Check
                        inline
                        disabled
                        name="activation_type"
                        label="OFF-STORE"
                        type={type}
                        id={`inline-activation_type-${type}-5`}
                        checked={primaryDetails.activation_type === "OFF-STORE"}
                      />
                    </>
                  )}
              </div>
            ))}
          </Form.Group>
          {user_type.toLowerCase() === "consumer" && (
            <>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Date</Form.Label>
                <Form.Control
                  disabled
                  name="date"
                  type="date"
                  value={primaryDetails.date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">
                  {primaryDetails.activation_type === "IN-STORE"
                    ? "ANS"
                    : "APS"}{" "}
                  Name
                </Form.Label>
                <Form.Control
                  disabled
                  name="name"
                  type="text"
                  value={primaryDetails.anc_ans_aps}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Store/Venue</Form.Label>
                <Form.Control
                  disabled
                  name="location_name"
                  type="text"
                  value={primaryDetails.location_name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">
                  Competitor's Activity
                </Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="remarks"
                  value={primaryDetails.remarks}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreImage">
                <Form.Label className="font-med">Image</Form.Label>
                {/* <Form.Control disabled accept="image/*" id="icon-button-file" type="file" capture="environment" value={primaryDetails.location_image}/> */}
              </Form.Group>
            </>
          )}
          {user_type.toLowerCase() === "patient" && (
            <>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Date</Form.Label>
                <Form.Control
                  disabled
                  name="date"
                  type="date"
                  value={primaryDetails.date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">ANC Name</Form.Label>
                <Form.Control
                  disabled
                  name="an_name"
                  type="text"
                  value={primaryDetails.anc_ans_aps}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Clinic/Hospital</Form.Label>
                <Form.Control
                  disabled
                  name="location_name"
                  type="text"
                  value={primaryDetails.location_name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">Name of Doctor/HCP</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="remarks"
                  value={primaryDetails.remarks}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreImage">
                <Form.Label className="font-med">Image</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="remarks"
                  value={primaryDetails.location_image[0].name}
                />
                {/* <Form.Control disabled accept="image/*" id="icon-button-file" type="file" capture="environment" value={primaryDetails.location_image[0].name}/> */}
              </Form.Group>
            </>
          )}
          {/* {primaryDetails.activation_type === "NKA" && (
            <>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Date</Form.Label>
                <Form.Control
                  disabled
                  name="date"
                  type="date"
                  value={primaryDetails.date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">ANC Name</Form.Label>
                <Form.Control
                  disabled
                  name="an_name"
                  type="text"
                  value={primaryDetails.anc_ans_aps}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Clinic/Hospital</Form.Label>
                <Form.Control
                  disabled
                  name="location_name"
                  type="text"
                  value={primaryDetails.location_name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">Name of Doctor/HCP</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="remarks"
                  value={primaryDetails.remarks}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreImage">
                <Form.Label className="font-med">Image</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="remarks"
                  value={primaryDetails.location_image[0].name}
                />
                <Form.Control disabled accept="image/*" id="icon-button-file" type="file" capture="environment" value={primaryDetails.location_image[0].name}/>
              </Form.Group>
            </>
          )} */}
          {/* {primaryDetails.activation_type === "LKA" && (
            <>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Date</Form.Label>
                <Form.Control
                  disabled
                  name="date"
                  type="date"
                  value={primaryDetails.date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">ANC Name</Form.Label>
                <Form.Control
                  disabled
                  name="an_name"
                  type="text"
                  value={primaryDetails.anc_ans_aps}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Clinic/Hospital</Form.Label>
                <Form.Control
                  disabled
                  name="location_name"
                  type="text"
                  value={primaryDetails.location_name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">Name of Doctor/HCP</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="remarks"
                  value={primaryDetails.remarks}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStoreImage">
                <Form.Label className="font-med">Image</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="remarks"
                  value={primaryDetails.location_image[0].name}
                />
                <Form.Control disabled accept="image/*" id="icon-button-file" type="file" capture="environment" value={primaryDetails.location_image[0].name}/>
              </Form.Group>
            </>
          )} */}
        </Form>
        <hr />
        <Form.Label className="subtitle">Customer Details</Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">Name</Form.Label>
            <Form.Control
              disabled
              name="customer_name"
              type="text"
              value={details.name}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">Phone Number</Form.Label>
            <Form.Control
              disabled
              name="contact_no"
              type="number"
              value={details.contact_no}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">Email Address</Form.Label>
            <Form.Control
              disabled
              name="email"
              type="text"
              value={details.email}
            />
          </Form.Group>
        </Form>
        <Form.Label className="subtitle">Product Details</Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Label className="font-med">Product Purchased </Form.Label>
          {productCodes.map((data) => {
            return (
              <Card className="mb-2">
                <Card.Header as="h5" style={{ background: "#ffbf00" }}>
                  {data.name}
                </Card.Header>
                <Card.Body>
                  <Form.Group className="" controlId="formGroupStoreName">
                    <Row>
                      <Col
                        xs={4}
                        style={{ display: "flex", justifyContent: "end" }}
                      ></Col>
                      <Col xs={4}>
                        <Form.Control
                          disabled
                          name="product_code"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={data.qty}
                        />
                      </Col>
                      <Col xs={4}></Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            );
          })}
          <Row>
            <Col xs={9} md={11} className="pl-2 pt-1">
              <Form.Label className="font-med">Promo Packs</Form.Label>
            </Col>
            <Col xs={3} md={1}></Col>
          </Row>
          {promoList.map((data) => {
            return (
              <Card className="mb-2">
                <Card.Header
                  as="h5"
                  style={{ background: "#219ebc", color: "whitesmoke" }}
                >
                  {data.name}
                </Card.Header>
                <Card.Body>
                  <Form.Group className="" controlId="formGroupStoreName">
                    <Row>
                      <Col
                        xs={4}
                        style={{ display: "flex", justifyContent: "end" }}
                      ></Col>
                      <Col xs={4}>
                        <Form.Control
                          disabled
                          name="product_code"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={data.qty}
                        />
                      </Col>
                      <Col xs={4}></Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            );
          })}
          {/* {primaryDetails.activation_type === "NKA" && (
            <>
              {promoList.map((data) => {
                return (
                  <Card className="mb-2">
                    <Card.Header
                      as="h5"
                      style={{ background: "#219ebc", color: "whitesmoke" }}
                    >
                      {data.name}
                    </Card.Header>
                    <Card.Body>
                      <Form.Group className="" controlId="formGroupStoreName">
                        <Row>
                          <Col
                            xs={4}
                            style={{ display: "flex", justifyContent: "end" }}
                          ></Col>
                          <Col xs={4}>
                            <Form.Control
                              disabled
                              name="product_code"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={data.qty}
                            />
                          </Col>
                          <Col xs={4}></Col>
                        </Row>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                );
              })}
            </>
          )} */}
          {/* {promoListLKA.map((data) => {
            return (
              <Card className="mb-2">
                <Card.Header
                  as="h5"
                  style={{ background: "#219ebc", color: "whitesmoke" }}
                >
                  {data.name}
                </Card.Header>
                <Card.Body>
                  <Form.Group className="" controlId="formGroupStoreName">
                    <Row>
                      <Col
                        xs={4}
                        style={{ display: "flex", justifyContent: "end" }}
                      ></Col>
                      <Col xs={4}>
                        <Form.Control
                          disabled
                          name="product_code"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={data.qty}
                        />
                      </Col>
                      <Col xs={4}></Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            );
          })} */}
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">Sample Given</Form.Label>
            {freebies.map((data) => {
              return (
                <Card className="mb-2">
                  <Card.Header as="h5" style={{ background: "#fb8500" }}>
                    {data.name}
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="" controlId="formGroupStoreName">
                      <Row>
                        <Col
                          xs={4}
                          style={{ display: "flex", justifyContent: "end" }}
                        ></Col>
                        <Col xs={4}>
                          <Form.Control
                            disabled
                            name="product_code"
                            type="text"
                            style={{ textAlign: "center" }}
                            value={data.qty}
                          />
                        </Col>
                        <Col xs={4}></Col>
                      </Row>
                    </Form.Group>
                  </Card.Body>
                </Card>
              );
            })}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">OR No.</Form.Label>
            <Form.Control
              disabled
              name="or_no"
              type="text"
              value={details.or_no}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">Consumer Target</Form.Label>
            {["checkbox"].map((type) => (
              <div key={`inlinecustomertarget-${type}`} className="mb-3">
                <Form.Check
                  inline
                  disabled
                  label="DIABETASOL"
                  name="is_diabetasol"
                  type={type}
                  id="is_diabetasol"
                  value={details.is_diabetasol}
                  checked={details.is_diabetasol === "1"}
                  // onClick={handleDetailsChange}
                />
                <Form.Group>
                  <Form.Label className="font-med">Diagnosis:</Form.Label>
                  <Form.Control
                    disabled
                    name="diabetasol_diagnosis"
                    value={details.diabetasol_diagnosis}
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </div>
            ))}
            {["checkbox"].map((type) => (
              <div key={`inlinecustomertarget-${type}`} className="mb-3">
                <Form.Check
                  inline
                  disabled
                  label="ENTRASOL"
                  name="is_entrasol"
                  value={details.is_entrasol}
                  type={type}
                  id="is_entrasol"
                  checked={details.is_entrasol === "1"}
                  // onClick={handleDetailsChange}
                />
                <Form.Group>
                  <Form.Label className="font-med">Diagnosis:</Form.Label>
                  <Form.Control
                    disabled
                    name="entrasol_diagnosis"
                    value={details.entrasol_diagnosis}
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </div>
            ))}
            {["checkbox"].map((type) => (
              <div key={`inlinecustomertarget-${type}`} className="mb-3">
                <Form.Check
                  inline
                  disabled
                  label="DIABETASOL SWEETENER"
                  name="is_diabetasol_sweetener"
                  type={type}
                  id="is_diabetasol_sweetener"
                  value={details.is_diabetasol_sweetener}
                  checked={details.is_diabetasol_sweetener === "1"}
                  // onClick={handleDetailsChange}
                />

                <Form.Group>
                  <Form.Label className="font-med">Diagnosis:</Form.Label>
                  <Form.Control
                    disabled
                    name="diabetasol_sweetener_diagnosis"
                    value={details.diabetasol_sweetener_diagnosis}
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </div>
            ))}
          </Form.Group>
        </Form>
        <Form.Label className="subtitle">
          {primaryDetails.activation_type === "IN-CLINIC"
            ? "Patient Type"
            : "Consumer Type"}
        </Form.Label>
        <Form style={{ textAlign: "left" }}>
          {user_type === "consumer" && (
            <>
              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">Best Time to Call</Form.Label>
                <Form.Control
                  disabled
                  name="call_time"
                  type="text"
                  value={details.call_time}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">BUMO</Form.Label>
                <Form.Control
                  disabled
                  name="bumo"
                  type="text"
                  value={details.bumo}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupStoreName">
                <Form.Label className="font-med">LC/NC/PTS</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inlinelc-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      disabled
                      label="LC"
                      name="group2"
                      type={type}
                      checked={details.lc_nc_p === "LC"}
                      id={`inlinelc-${type}-1`}
                    />
                    <Form.Check
                      inline
                      disabled
                      label="NC"
                      name="group2"
                      type={type}
                      id={`inlinelc-${type}-2`}
                      checked={details.lc_nc_p === "NC"}
                    />
                    <Form.Check
                      inline
                      disabled
                      label="PTS"
                      name="group2"
                      type={type}
                      id={`inlinelc-${type}-3`}
                      checked={details.lc_nc_p === "PTS"}
                    />
                  </div>
                ))}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">EU/CG</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inlineeu-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      disabled
                      label="EU"
                      name="group3"
                      type={type}
                      checked={details.eu_cg === "EU"}
                      id={`inlineeu-${type}-1`}
                    />
                    <Form.Check
                      inline
                      disabled
                      label="CG"
                      name="group3"
                      type={type}
                      id={`inlineeu-${type}-2`}
                      checked={details.eu_cg === "CG"}
                    />
                  </div>
                ))}
              </Form.Group>
            </>
          )}
          {user_type === "patient" && (
            <>
              {(primaryDetails.activation_type === "IN-CLINIC" ||
                primaryDetails.classification_type === "ANC-FULLBOOTH" ||
                primaryDetails.classification_type === "ANS" ||
                primaryDetails.classification_type === "ANC") && (
                <>
                  <Form.Group className="mb-3" controlId="formGroupStoreName">
                    <Form.Label className="font-med">
                      Best Time to Call
                    </Form.Label>
                    <Form.Control
                      disabled
                      name="call_time"
                      type="text"
                      value={details.call_time}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupStoreName">
                    <Form.Label className="font-med">Patient Type</Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          disabled
                          label="IN-PATIENT"
                          name="group1"
                          type={type}
                          checked={details.patient_type === "IN-PATIENT"}
                          id={`inline-${type}-1`}
                        />
                        <Form.Check
                          inline
                          disabled
                          label="OUT-PATIENT"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                          checked={details.patient_type === "OUT-PATIENT"}
                        />
                      </div>
                    ))}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupStoreName">
                    <Form.Label className="font-med">LC/NC/PTS</Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          disabled
                          label="LC"
                          name="group2"
                          type={type}
                          checked={details.lc_nc_p === "LC"}
                          id={`inline-${type}-1`}
                        />
                        <Form.Check
                          inline
                          disabled
                          label="NC"
                          name="group2"
                          type={type}
                          id={`inline-${type}-2`}
                          checked={details.lc_nc_p === "NC"}
                        />
                        <Form.Check
                          inline
                          disabled
                          label="PTS"
                          name="group2"
                          type={type}
                          id={`inline-${type}-2`}
                          checked={details.lc_nc_p === "PTS"}
                        />
                      </div>
                    ))}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupRemarks">
                    <Form.Label className="font-med">EU/CG</Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          disabled
                          label="EU"
                          name="group3"
                          type={type}
                          checked={details.eu_cg === "EU"}
                          id={`inline-${type}-1`}
                        />
                        <Form.Check
                          inline
                          disabled
                          label="CG"
                          name="group3"
                          type={type}
                          id={`inline-${type}-2`}
                          checked={details.eu_cg === "CG"}
                        />
                      </div>
                    ))}
                  </Form.Group>
                </>
              )}
            </>
          )}
          {(primaryDetails.activation_type !== "" ||
            primaryDetails.classification_type === "ANC-FULLBOOTH" ||
            primaryDetails.classification_type === "ANC" ||
            primaryDetails.classification_type === "ANS") && (
            <>
              <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
                <Button
                  variant="primary"
                  size="md"
                  className="full-width-btn confirm-btn logout"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Form.Group>
              <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
                {loading ? (
                  <Row className="justify-content-md-center">
                    <Col md={1} sm={1} xs={1} className="left-38">
                      <ReactLoading
                        type="spinningBubbles"
                        color="#87BD40"
                        height={100}
                        width={50}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Button
                    variant="primary"
                    size="md"
                    className="full-width-btn confirm-btn"
                    onClick={handleSubmit}
                  >
                    Confirm & Submit
                  </Button>
                )}
              </Form.Group>
            </>
          )}
        </Form>
        <Col xs={12} md={12} className="mt-3">
          <img
            src={diabetasol}
            alt="logo"
            width={130}
            height={60}
            style={{ borderRadius: "10px" }}
          />{" "}
          <img
            src={entrasol}
            alt="logo"
            width={130}
            height={60}
            style={{ borderRadius: "10px" }}
          />
        </Col>
        <Col xs={12} md={12} className="mt-1">
          <img
            src={logo}
            alt="logo"
            width={100}
            height={50}
            style={{ borderRadius: "10px" }}
          />
        </Col>
      </Row>
      <img src={swoosh} alt="logo" height={300} className={"swoosh"} />

      <Modal
        // show={false}
        show={openModal}
        onHide={() => setOpenModal(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header>
          <Row style={{ display: "contents" }}>
            <span className="title" style={{ textAlign: "center" }}>
              INFORMATION SUCCESSFULLY SAVED!
            </span>
          </Row>
        </Modal.Header>
        <Modal.Body className="action">
          <Form>
            <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn"
                onClick={handleAnotherRegistration}
              >
                Register Another Customer
              </Button>
            </Form.Group>
            {/* <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
                <Button variant="primary" size="md" className='full-width-btn confirm-btn' onClick={handleAnotherStore}>
                    Patient Registration Form
                </Button>
            </Form.Group>
            <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
                <Button variant="primary" size="md" className='full-width-btn confirm-btn change-location' onClick={()=>navigate("/location")}>
                    Change Location
                </Button>
            </Form.Group> */}
            <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn logout "
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={()=>setOpenStore(false)}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
      <Modal
        // show={false}
        show={showBack}
        onHide={() => setShowBack(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header>
          <Row>
            <span className="title" style={{ color: "#d00000" }}>
              ARE YOU SURE YOU WANT TO LEAVE THIS PAGE?
            </span>
          </Row>
        </Modal.Header>
        <Modal.Body className="action">
          <Row>
            <Col lg={6} md={6} sm={6} xs={6}>
              {/* <Form>
                <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit"> */}
              <Button
                variant="primary"
                size="md"
                className=" confirm-btn full-width-btn"
                onClick={() => navigation.previous()}
              >
                YES
              </Button>
              {/* </Form.Group>
              </Form> */}
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              {/* <Form>
                <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit"> */}
              <Button
                variant="primary"
                size="md"
                className="full-width-btn confirm-btn logout"
                onClick={() => setShowBack(false)}
              >
                NO
              </Button>
              {/* </Form.Group>
              </Form> */}
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={()=>setOpenStore(false)}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </Container>
  );
}

export default Summary;
