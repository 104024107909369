import {
  getAddress1,
  getAddress2,
  getBranch,
  getFirstName,
  getKey,
  getLastName,
  getLat1,
  getLat2,
  getLong1,
  getLong2,
  getToken,
  getUser,
  getUserName,
  getUserType,
} from "../Utils/Common";
import {
  getAPICall,
  postAPICall,
  putAPICall,
  deleteAPICall,
  API_KEY,
  TOKEN,
  postAPICall2,
  getAPICallGoogle,
} from "./axiosMethodCalls";

//Add Information API
export const createInfo = async (
  details,
  primaryDetails,
  productCodes,
  freebies,
  promoList
) => {
  try {
    var formData = new FormData();
    formData.append("requester", getUser());
    formData.append("token", getToken().replace(/['"]+/g, ""));

    formData.append("address1", getAddress1());
    formData.append("address2", getAddress2());
    formData.append("latitude1", getLat1());
    formData.append("longitude1", getLong1());
    formData.append("latitude2", getLat2());
    formData.append("longitude2", getLong2());

    for (const [key, value] of Object.entries(primaryDetails)) {
      if (key === "location_image") {
        formData.append(key, primaryDetails.location_image[0]);
      } else {
        formData.append(key, value);
      }
    }

    for (const [key, value] of Object.entries(details)) {
      formData.append(key, value);
    }

    productCodes.map((data) => {
      formData.append("purchased_products[]", data.qty);
    });

    if (promoList.length > 0) {
      promoList.map((data) => {
        formData.append("promotions[]", data.name);
        formData.append("promotion_qties[]", data.qty);
      });
    } else {
      formData.append("promotions[]", []);
      formData.append("promotion_qties[]", []);
    }

    if (freebies.length > 0) {
      freebies.map((data) => {
        formData.append("freebies[]", data.name);
        formData.append("freebie_qties[]", data.qty);
      });
    } else {
      formData.append("freebies[]", []);
      formData.append("freebie_qties[]", []);
    }

    const response = await postAPICall2(
      process.env.REACT_APP_LINK + "forms/add",
      formData
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

//Add Customer API
export const createCustomer = async (
  id,
  details,
  productCodes,
  freebies,
  promoList
) => {
  try {
    var formData = new FormData();
    formData.append("requester", getUser());
    formData.append("token", getToken().replace(/['"]+/g, ""));
    formData.append("form_id", id);

    for (const [key, value] of Object.entries(details)) {
      formData.append(key, value);
    }

    productCodes.map((data) => {
      formData.append("purchased_products[]", data.qty);
    });

    promoList.map((data) => {
      formData.append("promotions[]", data.name);
      formData.append("promotion_qties[]", data.qty);
    });

    freebies.map((data) => {
      formData.append("freebies[]", data.name);
      formData.append("freebie_qties[]", data.qty);
    });

    const response = await postAPICall2(
      process.env.REACT_APP_LINK + "forms/add_customer",
      formData
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

//Get Store API
export const getLocations = async (
  date_from,
  date_to,
  latitude,
  longitude,
  page
) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "forms/search",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
        date_from: date_from,
        date_to: date_to,
        encoded_by: `${getFirstName()} ${getLastName()}`,
        role: getUserType(),
        latitude: latitude,
        longitude: longitude,
        page: page,
      }
    );
    return { data: response.data.data };
  } catch (error) {
    return { error: error.response };
  }
};
export const getAccounts = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "accounts/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
      }
    );
    return { data: response.data.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const getPromos = async (activation_type, classification_type) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "promos/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
        classification_type: classification_type,
        activation_type: activation_type,
      }
    );
    return { data: response.data.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const getFreebies = async () => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "freebies/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
      }
    );
    return { data: response.data.data };
  } catch (error) {
    return { error: error.response };
  }
};

// GOOGLE API
export const getLocationOnLoad = async (lat, lng) => {
  try {
    const response = await getAPICallGoogle(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        lat +
        "," +
        lng +
        "&key=AIzaSyAHeix3Mf1YRppXp1hxArkPwkevOZFc1Ew"
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
