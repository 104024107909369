import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../Assets/Logo/kalbe.png";
import entrasol from "../../Assets/Logo/entrasol.png";
import diabetasol from "../../Assets/Logo/diabetasol.png";
import swoosh from "../../Assets/Logo/swoosh.png";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";

import "./Login.css";
import { loginUser } from "../../Helpers/API/authApi";
import { refreshPage } from "../../Helpers/Utils/Common";

function Login() {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showEye, setShowEye] = useState(false);
  const [loading, setLoading] = useState(false);

  async function login() {
    const response = await loginUser(credentials);
    if (response.data) {
      toast.success("Login Successful", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      });
      setTimeout(() => {
        localStorage.setItem(
          "first_name",
          JSON.stringify(response.data.first_name)
        );
        localStorage.setItem(
          "last_name",
          JSON.stringify(response.data.last_name)
        );
        localStorage.setItem("id", JSON.stringify(response.data.id));
        localStorage.setItem("token", JSON.stringify(response.data.token));
        localStorage.setItem(
          "username",
          JSON.stringify(response.data.username)
        );
        localStorage.setItem("type", JSON.stringify(response.data.type));
        navigate("/location");
        refreshPage();
      }, 2000);
    } else {
      setLoading(false);
      toast.error("Invalid Credentials", {
        autoClose: 4000,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  }

  function handleLogin() {
    setLoading(true);
    login();
  }

  return (
    <Container style={{ display: "flex", justifyContent: "center" }}>
      <ToastContainer style={{ textAlign: "left" }} />
      <Row className="justify-content-md-center p-5 pt-0 login-child">
        <Col md={12} className="pb-3">
          <img src={logo} alt="logo" width={300} />
        </Col>
        <Col md={12} className="mt-5">
          <Row className="justify-content-md-center">
            <Col md={6}>
              <div class="input-group mb-3">
                <span class="input-group-text login-input" id="basic-addon1">
                  <FontAwesomeIcon
                    icon={"fa-user"}
                    alt={"user"}
                    aria-hidden="true"
                  />
                </span>
                <input
                  type="text"
                  class="form-control login-input"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  // maxLength={11}
                  onChange={(e) =>
                    setCredentials({ ...credentials, username: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <div class="input-group mb-3">
                <span class="input-group-text login-input" id="basic-addon1">
                  <FontAwesomeIcon
                    icon={"fa-lock"}
                    alt={"lock"}
                    aria-hidden="true"
                  />
                </span>
                <input
                  type={!showEye ? "password" : "text"}
                  class="form-control login-input br-right-none"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="basic-addon1"
                  onChange={(e) =>
                    setCredentials({ ...credentials, password: e.target.value })
                  }
                />
                <span
                  class="input-group-text login-input password-input "
                  id="basic-addon1"
                  onClick={() => setShowEye(!showEye)}
                >
                  <FontAwesomeIcon
                    icon={!showEye ? "fa-eye" : "fa-eye-slash"}
                    alt={"user"}
                    aria-hidden="true"
                  />
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          {loading ? (
            <Row className="justify-content-md-center">
              <Col md={1} sm={1} xs={1} className="left-38">
                <ReactLoading
                  type="spinningBubbles"
                  color="#87BD40"
                  height={100}
                  width={50}
                />
              </Col>
            </Row>
          ) : (
            <Button
              variant="primary mb-3"
              size="lg"
              className="full-width-btn-login login-btn"
              onClick={handleLogin}
            >
              LOGIN
            </Button>
          )}
        </Col>
        {/* <Row style={{display:"flex", justifyContent:"center", padding:"2px"}}> */}
        <Col xs={12} md={12}>
          <img
            src={diabetasol}
            alt="logo"
            width={145}
            height={70}
            style={{ borderRadius: "10px" }}
          />{" "}
          <img
            src={entrasol}
            alt="logo"
            width={145}
            height={70}
            style={{ borderRadius: "10px" }}
          />
        </Col>

        {/* {" "}
          <Col xs={6} >
            
          </Col> */}
        {/* </Row> */}
      </Row>
      {/* <Row> */}
      {/* <div className={"mb-0 p-0"} > */}
      <img src={swoosh} alt="logo" height={500} className={"swoosh"} />
      {/* </div>
      </Row> */}
    </Container>
  );
}

export default Login;
