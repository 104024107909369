import { logoutUser } from '../API/authApi';


/***************************
 * Common Utilities
 ***************************/
export const numberWithCommas = (number) => {
    if(!number) {
        return
    }
    
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//Common Utility Functions

export const refreshPage = () => {
    window.location.reload();
}

export const getTime = (date) => {
    return  date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

export const getTime12Hour = (date) => {
    var today = new Date(date)
    return today.getHours() + ':' + today.getMinutes()
}

export const subtractMonths = (numOfMonths, date = new Date()) => {
   date.setMonth(date.getMonth() - numOfMonths);
   return date.toISOString().split('T')[0];
}

export const getTodayDate = () => {
    return new Date();
}

export const getTodayDateISO = () => {
    let date =  new Date();
    return date.toISOString().split('T')[0]
}

export const getAge = (dateString) => {
    var today = new Date();
    var birthdate = new Date(dateString);
    var age = today.getFullYear() - birthdate.getFullYear();
    var m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
        age--;
    }
    return age;
}

export const formatPrice = (p)=> {
    var result = parseFloat(p).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return result;
};

export const formatDate = (date) => {
    var formatDate = new Date(date);
    var stringDate = formatDate.toDateString().split(" ");
    return stringDate[1] + " " + stringDate[2] + ", " + stringDate[3]
}

export const formatDateWithTime = (date) => {
    var formatDate = new Date(date);
    var stringDate = formatDate.toDateString().split(" ");
    return stringDate[1] + " " + stringDate[2] + ", " + stringDate[3] + " " + formatDate.toLocaleTimeString().replace(/(.*)\D\d+/, "$1")
}

export const formatDateNoTime = (date) => {
    var formatDate = new Date(date);
    var stringDate = formatDate.toDateString().split(" ");

    return stringDate[1] + " " + stringDate[2] + ", " + stringDate[3];
}

export const formatDateSlash = (date) => {
    var stringDate = date.split("-")

    return stringDate[1] + "/" + stringDate[2] + "/" + stringDate[0]
}

export const formatMDY = (date) => {
    var stringDate = date.split("-")
    return stringDate[1] + "-" + stringDate[2] + "-" + stringDate[0]
}

export const validateEmail = (email) => {
    if(email === "") {
        return true
    } else {
        var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(email).search (filter) != -1;
    }
};

export const validateContact = (num) => {
    var mobileFilter = /((^(\+)(\d){12}$)|(^\d{11}$))/;
    var telFilter = /^[0-9]+(-[0-9]+)+$/;

    if(String(num).search(mobileFilter) != -1 || String(num).search(telFilter) != - 1) {
        return true
    } else {
        return false
    }
}

export const formatNum = (num) => {
    return parseFloat(Math.round(num * 100) / 100).toFixed(2);
}




/***************************
 * Local Storage Utilities
 ***************************/


//return user data from local storage
export const getUser = () => {
    const userStr = localStorage.getItem('id');
    if(userStr) return JSON.parse(userStr);
    else return null;
}

//return username from local storage
export const getUserName = () => {
    const userStr = localStorage.getItem('username');
    if(userStr) return JSON.parse(userStr);
    else return null;
}

//return first name from local storage
export const getFirstName = () => {
    const userStr = localStorage.getItem('first_name');
    if(userStr) return JSON.parse(userStr);
    else return null;
}

//return first name from local storage
export const getLastName = () => {
    const userStr = localStorage.getItem('last_name');
    if(userStr) return JSON.parse(userStr);
    else return null;
}

//return token from local storage
export const getToken = () => {
    return localStorage.getItem('token') || null;
}

//return token expiry from local storage
export const getTokenExpiry = () => {
    return localStorage.getItem('token_expiry') || null;
}

export const getUserType = () => {
    const user_type = localStorage.getItem('type');
    // const user_type = '"consumer"';
    if(user_type) return JSON.parse(user_type);
    else return null;
}

export const getAddress1 = () => {
    const address1 = localStorage.getItem('address1');
    if(address1) return JSON.parse(address1);
    else return null;
}

export const getAddress2 = () => {
    const address2 = localStorage.getItem('address2');
    if(address2) return JSON.parse(address2);
    else return null;
}

export const getLat1 = () => {
    const latitude1 = localStorage.getItem('latitude1');
    if(latitude1) return JSON.parse(latitude1);
    else return null;
}

export const getLat2 = () => {
    const latitude2 = localStorage.getItem('latitude2');
    if(latitude2) return JSON.parse(latitude2);
    else return null;
}

export const getLong1 = () => {
    const longitude1 = localStorage.getItem('longitude1');
    if(longitude1) return JSON.parse(longitude1);
    else return null;
}

export const getLong2 = () => {
    const longitude2 = localStorage.getItem('longitude2');
    if(longitude2) return JSON.parse(longitude2);
    else return null;
}

//return store_address from local storage
export const getStreetAddress = () => {
    const address = localStorage.getItem('street_address');
    if(address) return JSON.parse(address);
    else return null;
}


//remove token from local storage
export const removeUserSession = () => {
    logoutUser();
    localStorage.clear();
}

//set the token and user from local storage
export const setUserSession = (token, api_key, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('api_key', api_key);
    localStorage.setItem('user', JSON.stringify(user));
}