import { getUserType } from "../Utils/Common";
import { handleValidationChange } from "./CommonValidation";

export const validatePrimaryDetails = (data, setIsError) => {
  var isValid = true;
  // var isValidActivation = true;
  var isValidClassification = true;
  var isValidANSANC = true;
  var isValidLocName = true;
  var isValidRemarks = true;
  var isValidLocImg = true;

  // if(data.activation_type === "") {
  //     handleValidationChange("activation_type", true, setIsError);
  //     isValidActivation = false;
  // } else {
  //     handleValidationChange("activation_type", false, setIsError);
  //     isValidActivation = true;
  // }

  if (data.classification_type === "") {
    handleValidationChange("classification_type", true, setIsError);
    isValidClassification = false;
  } else {
    handleValidationChange("classification_type", false, setIsError);
    isValidClassification = true;
  }

  if (
    data.anc_ans_aps === "" ||
    data.anc_ans_aps.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("anc_ans_aps", true, setIsError);
    isValidANSANC = false;
  } else {
    handleValidationChange("anc_ans_aps", false, setIsError);
    isValidANSANC = true;
  }

  if (
    data.location_name === "" ||
    data.location_name === "Select" ||
    data.location_name.replace(/\s/g, "").length === 0
  ) {
    handleValidationChange("location_name", true, setIsError);
    isValidLocName = false;
  } else {
    handleValidationChange("location_name", false, setIsError);
    isValidLocName = true;
  }

  if (data.remarks === "" || data.remarks.replace(/\s/g, "").length === 0) {
    handleValidationChange("remarks", true, setIsError);
    isValidRemarks = false;
  } else {
    handleValidationChange("remarks", false, setIsError);
    isValidRemarks = true;
  }

  if (data.location_image === "") {
    handleValidationChange("location_image", true, setIsError);
    isValidLocImg = false;
  } else {
    handleValidationChange("location_image", false, setIsError);
    isValidLocImg = true;
  }

  isValid =
    isValid &&
    //   isValidActivation &&
    isValidANSANC &&
    isValidLocName &&
    isValidRemarks &&
    isValidLocImg &&
    isValidClassification;

  return isValid;
};

// export const validateClaasificationDetails = (data, setIsError) => {

//     var isValid = true;
//     var isValidClassification = true;
//     var isValidANSANC = true;
//     var isValidLocName = true;
//     var isValidRemarks = true;
//     var isValidLocImg = true;

//     if(data.classification_type === "") {
//         handleValidationChange("classification_type", true, setIsError);
//         isValidClassification = false;
//     } else {
//         handleValidationChange("classification_type", false, setIsError);
//         isValidClassification = true;
//     }

//     if(data.anc_ans_aps === "") {
//         handleValidationChange("anc_ans_aps", true, setIsError);
//         isValidANSANC = false;
//     } else {
//         handleValidationChange("anc_ans_aps", false, setIsError);
//         isValidANSANC = true;
//     }

//     if(data.location_name === "") {
//         handleValidationChange("location_name", true, setIsError);
//         isValidLocName = false;
//     } else {
//         handleValidationChange("location_name", false, setIsError);
//         isValidLocName = true;
//     }

//     if(data.remarks === "") {
//         handleValidationChange("remarks", true, setIsError);
//         isValidRemarks = false;
//     } else {
//         handleValidationChange("remarks", false, setIsError);
//         isValidRemarks = true;
//     }

//     if(data.location_image === "") {
//         handleValidationChange("location_image", true, setIsError);
//         isValidLocImg = false;
//     } else {
//         handleValidationChange("location_image", false, setIsError);
//         isValidLocImg = true;
//     }

//     isValid = isValid &&
//               isValidANSANC &&
//               isValidLocName && isValidRemarks &&
//               isValidLocImg && isValidClassification

//     return isValid

// }

export const validateDetails = (data, productCodes, setIsError) => {
  var type = getUserType();
  var isValid = true;
  var isValidName = true;
  var isValidContact = true;
  var isValidEmail = true;
  var isValidOR = true;
  var isValidPatientType = true;
  var isValidLCNCP = true;
  var isValidEUCG = true;
  var isValidBumo = true;
  var isValidProductCodes = false;
  var isValidTimeCall = true;
  var isValidCallTime = true;
  var isValidDiabetasolDiagnosis = true;
  var isValidEntrasolDiagnosis = true;
  var isValidDiabetasolSweetenerDiagnosis = true;
  var isValidConsumerEntry = true;

  // productCodes.map(data=>{
  //     isValidProductCodes = isValidProductCodes || (parseInt(data.qty) > 0)
  // })
  // if(isValidProductCodes){
  //     handleValidationChange("product_codes", false, setIsError);
  // }
  // else{
  //     handleValidationChange("product_codes", true, setIsError);
  // }

  if (data.is_diabetasol === "1") {
    if (
      data.diabetasol_diagnosis === "" ||
      data.diabetasol_diagnosis.replace(/\s/g, "").length === 0
    ) {
      handleValidationChange("diabetasol_diagnosis", true, setIsError);
      isValidDiabetasolDiagnosis = false;
    } else {
      handleValidationChange("diabetasol_diagnosis", false, setIsError);
      isValidDiabetasolDiagnosis = true;
    }
  } else {
    handleValidationChange("diabetasol_diagnosis", false, setIsError);
    isValidDiabetasolDiagnosis = true;
  }

  if (data.is_entrasol === "1") {
    if (
      data.entrasol_diagnosis === "" ||
      data.entrasol_diagnosis.replace(/\s/g, "").length === 0
    ) {
      handleValidationChange("entrasol_diagnosis", true, setIsError);
      isValidEntrasolDiagnosis = false;
    } else {
      handleValidationChange("entrasol_diagnosis", false, setIsError);
      isValidEntrasolDiagnosis = true;
    }
  } else {
    handleValidationChange("entrasol_diagnosis", false, setIsError);
    isValidEntrasolDiagnosis = true;
  }

  if (data.is_diabetasol_sweetener === "1") {
    if (
      data.diabetasol_sweetener_diagnosis === "" ||
      data.diabetasol_sweetener_diagnosis.replace(/\s/g, "").length === 0
    ) {
      handleValidationChange(
        "diabetasol_sweetener_diagnosis",
        true,
        setIsError
      );
      isValidDiabetasolSweetenerDiagnosis = false;
    } else {
      handleValidationChange(
        "diabetasol_sweetener_diagnosis",
        false,
        setIsError
      );
      isValidDiabetasolSweetenerDiagnosis = true;
    }
  } else {
    handleValidationChange("diabetasol_sweetener_diagnosis", false, setIsError);
    isValidDiabetasolSweetenerDiagnosis = true;
  }

  if (data.name === "" || data.name.replace(/\s/g, "").length === 0) {
    handleValidationChange("name", true, setIsError);
    isValidName = false;
  } else {
    handleValidationChange("name", false, setIsError);
    isValidName = true;
  }

  if (data.contact_no === "") {
    handleValidationChange("contact_no", true, setIsError);
    isValidContact = false;
  } else {
    handleValidationChange("contact_no", false, setIsError);
    isValidContact = true;
  }

  // if (data.email === "") {
  //   handleValidationChange("email", true, setIsError);
  //   isValidEmail = false;
  // } else {
  //   handleValidationChange("email", false, setIsError);
  //   isValidEmail = true;
  // }

  if (data.call_time === "" || data.call_time === "Select...") {
    handleValidationChange("call_time", true, setIsError);
    isValidCallTime = false;
  } else {
    handleValidationChange("call_time", false, setIsError);
    isValidCallTime = true;
  }

  //   if (data.or_no === "") {
  //     handleValidationChange("or_no", true, setIsError);
  //     isValidOR = false;
  //   } else {
  //     handleValidationChange("or_no", false, setIsError);
  //     isValidOR = true;
  //   }

  if (type === "patient") {
    if (data.patient_type === "") {
      handleValidationChange("patient_type", true, setIsError);
      isValidPatientType = false;
    } else {
      handleValidationChange("patient_type", false, setIsError);
      isValidPatientType = true;
    }
  }

  if (type === "consumer") {
    if (data.bumo === "") {
      handleValidationChange("bumo", true, setIsError);
      isValidBumo = false;
    } else {
      handleValidationChange("bumo", false, setIsError);
      isValidBumo = true;
    }
  }

  if (data.lc_nc_p === "") {
    handleValidationChange("lc_nc_p", true, setIsError);
    isValidLCNCP = false;
  } else {
    handleValidationChange("lc_nc_p", false, setIsError);
    isValidLCNCP = true;
  }

  if (data.eu_cg === "") {
    handleValidationChange("eu_cg", true, setIsError);
    isValidEUCG = false;
  } else {
    handleValidationChange("eu_cg", false, setIsError);
    isValidEUCG = true;
  }

  if (
    data.is_diabetasol === "0" &&
    data.is_entrasol === "0" &&
    data.is_diabetasol_sweetener === "0"
  ) {
    handleValidationChange("consumer_entry", true, setIsError);
    isValidConsumerEntry = false;
  } else {
    handleValidationChange("consumer_entry", false, setIsError);
    isValidConsumerEntry = true;
  }

  isValid =
    isValid &&
    isValidName &&
    isValidContact &&
    // isValidEmail &&
    isValidOR &&
    isValidPatientType &&
    isValidBumo &&
    isValidLCNCP &&
    isValidEUCG &&
    isValidCallTime &&
    isValidDiabetasolDiagnosis &&
    isValidDiabetasolSweetenerDiagnosis &&
    isValidEntrasolDiagnosis &&
    isValidConsumerEntry;

  return isValid;
};
