import {postAPICall } from './axiosMethodCalls';
import { getBranch, getUser, getKey, getToken, refreshPage } from '../Utils/Common';


/***************************
 * LOGIN
 ***************************/

//POST
 export const loginUser = async (credentials) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + 'logins', {
            username: credentials.username,
            password: credentials.password,
        });
        
        return ({data:response.data.data});   
    } catch (error) {
        return ({error: error.response});
    }
}

/***************************
 * LOGOUT
 ***************************/

export const logoutUser = async () => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + 'logouts', {
            requester: getUser(),
            token: getToken().replace(/['"]+/g, ''),
            login_id: getUser()
        });
        return response
    } catch (error) {
        return ({error: error.response});
    }
}