import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Card,
  ListGroup,
  FormGroup,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import { refreshPage, removeUserSession } from "../../Helpers/Utils/Common";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../Assets/Logo/kalbe.png";
import entrasol from "../../Assets/Logo/entrasol.png";
import diabetasol from "../../Assets/Logo/diabetasol.png";
import swoosh from "../../Assets/Logo/swoosh.png";
import {
  validateDetails,
  validatePrimaryDetails,
} from "../../Helpers/Validation/FormValidation";
import InputError from "../../Components/InputError/InputError";
import {
  getStreetAddress,
  getUser,
  getUserType,
} from "../../Helpers/Utils/Common";

function Consumer({
  navigation,
  details,
  setDetails,
  primaryDetails,
  productCodes,
  setProductCodes,
  freebies,
  setFreebies,
  promoList,
  setPromoList,
  promoListLKA,
  setPromoListLKA,
  checked,
  setChecked,
  checkedDiabetasol,
  setCheckedDiabetasol,
  checkedEntrasol,
  setCheckedEntrasol,
  checkedDiabetasolSweetener,
  setCheckedDiabetasolSweetener,
}) {
  const user_type = getUserType().toLowerCase();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isError, setIsError] = useState({
    //CUSTOMER DETAILS
    name: false,
    contact_no: false,
    email: false,
    bumo: false, //FOR INSTORE/OFFSTORE
    patient_type: false, //FOR INCLINIC
    lc_nc_p: false,
    eu_cg: false,
    or_no: false,
    product_codes: false,
    time_call: false,
    call_time: false,
    // is_diabetasol:false,
    // is_entrasol: false,
    // is_diabetasol_sweetener: false,
    diabetasol_diagnosis: false,
    entrasol_diagnosis: false,
    diabetasol_sweetener_diagnosis: false,
    consumer_entry: false,
  });

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setChecked(!checked);
    // Don't miss the exclamation mark
  };

  const checkboxHandlerConsumer = () => {
    setCheckedDiabetasol(!checkedDiabetasol);
  };

  const checkboxHandlerConsumer2 = () => {
    setCheckedEntrasol(!checkedEntrasol);
  };

  const checkboxHandlerConsumer3 = () => {
    setCheckedDiabetasolSweetener(!checkedDiabetasolSweetener);
  };

  function handleDetailsChange(e) {
    const { name, value, checked } = e.target;

    if (name === "contact_no") {
      if (value.match(/^[0-9]*$/)) {
        setDetails({ ...details, [name]: value });
      }
    } else {
      setDetails({ ...details, [name]: value });
      if (value === "is_diabetasol") {
        setDetails({ ...details, [value]: checked ? "1" : "0" });
        if (!checked) {
          setDetails({ ...details, diabetasol_diagnosis: "", [value]: "0" });
        }
      }
      if (value === "is_entrasol") {
        setDetails({ ...details, [value]: checked ? "1" : "0" });
        if (!checked) {
          setDetails({ ...details, entrasol_diagnosis: "", [value]: "0" });
        }
      }
      if (value === "is_diabetasol_sweetener") {
        setDetails({ ...details, [value]: checked ? "1" : "0" });
        if (!checked) {
          setDetails({
            ...details,
            diabetasol_sweetener_diagnosis: "",
            [value]: "0",
          });
        }
      }
    }
  }

  function handleSubmit() {
    if (validateDetails(details, productCodes, setIsError)) {
      navigation.next();
    }
  }

  function handleAddProductQty(val) {
    var arr = [];
    productCodes.map((data) => {
      if (val.name === data.name) {
        data.qty = parseInt(data.qty) + 1;
      }
      arr.push(data);
    });
    setProductCodes(arr);
  }

  function handleSubtractProductQty(val) {
    var arr = [];
    productCodes.map((data) => {
      if (val.name === data.name && data.qty !== 0) {
        data.qty = parseInt(data.qty) - 1;
      }
      arr.push(data);
    });
    setProductCodes(arr);
  }

  function handleAddProductPromoQty(val) {
    var arr = [];
    promoList.map((data) => {
      if (val.name === data.name) {
        data.qty = parseInt(data.qty) + 1;
      }
      arr.push(data);
    });
    setPromoList(arr);
  }

  function handleSubtractProductPromoQty(val) {
    var arr = [];
    promoList.map((data) => {
      if (val.name === data.name && data.qty !== 0) {
        data.qty = parseInt(data.qty) - 1;
      }
      arr.push(data);
    });
    setPromoList(arr);
  }

  function handleAddProductLKAPromoQty(val) {
    var arr = [];
    promoListLKA.map((data) => {
      if (val.name === data.name) {
        data.qty = parseInt(data.qty) + 1;
      }
      arr.push(data);
    });
    setPromoListLKA(arr);
  }

  function handleSubtractLKAProductPromoQty(val) {
    var arr = [];
    promoListLKA.map((data) => {
      if (val.name === data.name && data.qty !== 0) {
        data.qty = parseInt(data.qty) - 1;
      }
      arr.push(data);
    });
    setPromoListLKA(arr);
  }

  function handleAddFreebieQty(val) {
    var arr = [];
    freebies.map((data) => {
      if (val.name === data.name) {
        data.qty = parseInt(data.qty) + 1;
      }
      arr.push(data);
    });
    setFreebies(arr);
  }

  function handleSubtractFreebieQty(val) {
    var arr = [];
    freebies.map((data) => {
      if (val.name === data.name && data.qty !== 0) {
        data.qty = parseInt(data.qty) - 1;
      }
      arr.push(data);
    });
    setFreebies(arr);
  }

  return (
    <Container>
      <ToastContainer />
      <Row Name="justify-content-md-center p-5 mt-10">
        <Form.Group
          style={{ textAlign: "left" }}
          className="mb-3 mt-3"
          controlId="formAgree"
        >
          {["checkbox"].map((type) => (
            <div key={`inlinelc-${type}`} className="mb-3">
              <Form.Label for={`inlinelc-${type}-1`}>
                <Form.Check
                  inline
                  value="PRIVACY-POLICY"
                  name="privacy_policy"
                  checked={checked}
                  type={type}
                  id={`inlinelc-${type}-1`}
                  onClick={checkboxHandler}
                ></Form.Check>
                I agree to the terms and conditions provided by Kalbe
                International. Click link to view{" "}
                <a
                  target="_blank"
                  href="https://kalbe-api.purplegiantsolutions.com/data_privacy_act.pdf"
                >
                  Terms and Conditions
                </a>{" "}
                . <span className="required-badge">*</span>
              </Form.Label>
            </div>
          ))}
        </Form.Group>
        <Form.Label className="subtitle">Customer Details</Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">
              Name <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={details.name}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.name}
              message={"This field is required*"}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">
              Phone Number <span className="required-badge">*</span>
            </Form.Label>
            <Form.Control
              name="contact_no"
              type="text"
              maxLength={11}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={details.contact_no}
              onChange={handleDetailsChange}
            />
            <InputError
              isValid={isError.contact_no}
              message={"This field is required*"}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">
              Email Address/FB Account
            </Form.Label>
            <Form.Control
              name="email"
              type="text"
              value={details.email}
              onChange={handleDetailsChange}
            />
          </Form.Group>
        </Form>
        <Form.Label className="subtitle">Product Details</Form.Label>
        <Form style={{ textAlign: "left" }}>
          <Form.Label className="font-med">Product Purchased</Form.Label>
          {productCodes.map((data) => {
            return (
              <Card className="mb-2">
                <Card.Header as="h5" style={{ background: "#ffbf00" }}>
                  {data.name}
                </Card.Header>
                <Card.Body>
                  <Form.Group className="" controlId="formGroupStoreName">
                    <Row>
                      <Col
                        xs={4}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <span id="basic-addon1">
                          <FontAwesomeIcon
                            className="pt-1"
                            style={{ cursor: "pointer" }}
                            size="xl"
                            icon={"fa-square-minus"}
                            alt={"user"}
                            aria-hidden="true"
                            color="var(--primary-color)"
                            onClick={() => handleSubtractProductQty(data)}
                          />
                        </span>
                      </Col>
                      <Col xs={4}>
                        <Form.Control
                          name="product_code"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={data.qty}
                          onChange={handleDetailsChange}
                          disabled
                        />
                      </Col>
                      <Col xs={4}>
                        <span id="basic-addon1">
                          <FontAwesomeIcon
                            className="pt-1"
                            style={{ cursor: "pointer" }}
                            size="xl"
                            icon={"fa-square-plus"}
                            alt={"user"}
                            aria-hidden="true"
                            color="var(--primary-color)"
                            onClick={() => handleAddProductQty(data)}
                          />
                        </span>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            );
          })}

          <Row>
            <Col xs={9} md={11} className="pl-2 pt-1">
              <Form.Label className="font-med">Promo Packs</Form.Label>
            </Col>
            <Col xs={3} md={1}></Col>
          </Row>
          {promoList.map((data) => {
            return (
              <Card className="mb-2">
                <Card.Header
                  as="h5"
                  style={{ background: "#219ebc", color: "whitesmoke" }}
                >
                  {data.name}
                </Card.Header>
                <Card.Body>
                  <Form.Group className="" controlId="formGroupStoreName">
                    <Row>
                      <Col
                        xs={4}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <span id="basic-addon1">
                          <FontAwesomeIcon
                            className="pt-1"
                            style={{ cursor: "pointer" }}
                            size="xl"
                            icon={"fa-square-minus"}
                            alt={"user"}
                            aria-hidden="true"
                            color="var(--primary-color)"
                            onClick={() => handleSubtractProductPromoQty(data)}
                          />
                        </span>
                      </Col>
                      <Col xs={4}>
                        <Form.Control
                          name="product_code"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={data.qty}
                          onChange={handleDetailsChange}
                          disabled
                        />
                      </Col>
                      <Col xs={4}>
                        <span id="basic-addon1">
                          <FontAwesomeIcon
                            className="pt-1"
                            style={{ cursor: "pointer" }}
                            size="xl"
                            icon={"fa-square-plus"}
                            alt={"user"}
                            aria-hidden="true"
                            color="var(--primary-color)"
                            onClick={() => handleAddProductPromoQty(data)}
                          />
                        </span>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            );
          })}
          {/* {(primaryDetails.activation_type === "NKA" ||
            primaryDetails.activation_type === "IN-CLINIC" ||
            primaryDetails.classification_type === "ANS" ||
            primaryDetails.classification_type === "ANC" ||
            primaryDetails.classification_type === "ANC-FULLBOOTH") && (
            <>
              {promoList.map((data) => {
                return (
                  <Card className="mb-2">
                    <Card.Header
                      as="h5"
                      style={{ background: "#219ebc", color: "whitesmoke" }}
                    >
                      {data.name}
                    </Card.Header>
                    <Card.Body>
                      <Form.Group className="" controlId="formGroupStoreName">
                        <Row>
                          <Col
                            xs={4}
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <span id="basic-addon1">
                              <FontAwesomeIcon
                                className="pt-1"
                                style={{ cursor: "pointer" }}
                                size="xl"
                                icon={"fa-square-minus"}
                                alt={"user"}
                                aria-hidden="true"
                                color="var(--primary-color)"
                                onClick={() =>
                                  handleSubtractProductPromoQty(data)
                                }
                              />
                            </span>
                          </Col>
                          <Col xs={4}>
                            <Form.Control
                              name="product_code"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={data.qty}
                              onChange={handleDetailsChange}
                              disabled
                            />
                          </Col>
                          <Col xs={4}>
                            <span id="basic-addon1">
                              <FontAwesomeIcon
                                className="pt-1"
                                style={{ cursor: "pointer" }}
                                size="xl"
                                icon={"fa-square-plus"}
                                alt={"user"}
                                aria-hidden="true"
                                color="var(--primary-color)"
                                onClick={() => handleAddProductPromoQty(data)}
                              />
                            </span>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                );
              })}
            </>
          )} */}

          {/* {primaryDetails.activation_type === "LKA" && (
            <>
              {promoListLKA.map((data) => {
                return (
                  <Card className="mb-2">
                    <Card.Header
                      as="h5"
                      style={{ background: "#219ebc", color: "whitesmoke" }}
                    >
                      {data.name}
                    </Card.Header>
                    <Card.Body>
                      <Form.Group className="" controlId="formGroupStoreName">
                        <Row>
                          <Col
                            xs={4}
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <span id="basic-addon1">
                              <FontAwesomeIcon
                                className="pt-1"
                                style={{ cursor: "pointer" }}
                                size="xl"
                                icon={"fa-square-minus"}
                                alt={"user"}
                                aria-hidden="true"
                                color="var(--primary-color)"
                                onClick={() =>
                                  handleSubtractLKAProductPromoQty(data)
                                }
                              />
                            </span>
                          </Col>
                          <Col xs={4}>
                            <Form.Control
                              name="product_code"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={data.qty}
                              onChange={handleDetailsChange}
                              disabled
                            />
                          </Col>
                          <Col xs={4}>
                            <span id="basic-addon1">
                              <FontAwesomeIcon
                                className="pt-1"
                                style={{ cursor: "pointer" }}
                                size="xl"
                                icon={"fa-square-plus"}
                                alt={"user"}
                                aria-hidden="true"
                                color="var(--primary-color)"
                                onClick={() =>
                                  handleAddProductLKAPromoQty(data)
                                }
                              />
                            </span>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                );
              })}
            </>
          )} */}

          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">Sample Given</Form.Label>
            {freebies.map((data) => {
              return (
                <Card className="mb-2">
                  <Card.Header as="h5" style={{ background: "#fb8500" }}>
                    {data.name}
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="" controlId="formGroupStoreName">
                      <Row>
                        <Col
                          xs={4}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span id="basic-addon1">
                            <FontAwesomeIcon
                              className="pt-1"
                              style={{ cursor: "pointer" }}
                              size="xl"
                              icon={"fa-square-minus"}
                              alt={"user"}
                              aria-hidden="true"
                              color="var(--primary-color)"
                              onClick={() => handleSubtractFreebieQty(data)}
                            />
                          </span>
                        </Col>
                        <Col xs={4}>
                          <Form.Control
                            name="product_code"
                            type="text"
                            style={{ textAlign: "center" }}
                            value={data.qty}
                            onChange={handleDetailsChange}
                            disabled
                          />
                        </Col>
                        <Col xs={4}>
                          <span id="basic-addon1">
                            <FontAwesomeIcon
                              className="pt-1"
                              style={{ cursor: "pointer" }}
                              size="xl"
                              icon={"fa-square-plus"}
                              alt={"user"}
                              aria-hidden="true"
                              color="var(--primary-color)"
                              onClick={() => handleAddFreebieQty(data)}
                            />
                          </span>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Card.Body>
                </Card>
              );
            })}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">OR No.</Form.Label>
            <Form.Control
              name="or_no"
              type="text"
              value={details?.or_no}
              onChange={handleDetailsChange}
            />
          </Form.Group>
          <Row className="d-flex row justify-content-center">
            <Col className="text-center mb-4">
              <div className="subtitle mt-1">
                {user_type === "patient" ? "Patient Type" : "Consumer Type"}
              </div>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formGroupStoreName">
            <Form.Label className="font-med">
              Consumer Target <span className="required-badge">*</span>
            </Form.Label>
            <InputError
              isValid={isError.consumer_entry}
              message={"This field is required*"}
            />
            {["checkbox"].map((type) => (
              <div key={`inlinecustomertarget-${type}`} className="mb-1">
                <Form.Check
                  inline
                  label="DIABETASOL"
                  value="is_diabetasol"
                  name="is_diabetasol"
                  type={type}
                  id="is_diabetasol"
                  checked={checkedDiabetasol}
                  onClick={(e) => checkboxHandlerConsumer(e)}
                  onChange={(e) => handleDetailsChange(e)}
                />
              </div>
            ))}
            {checkedDiabetasol && (
              <Form.Group>
                <Form.Label className="font-med">Diagnosis:</Form.Label>
                {/* <Row> */}
                {/* <Form.Group> */}
                <textarea
                  name="diabetasol_diagnosis"
                  value={details?.diabetasol_diagnosis}
                  className="form-control ms-1"
                  onChange={handleDetailsChange}
                  type="text"
                ></textarea>
                <InputError
                  isValid={isError.diabetasol_diagnosis}
                  message={"This field is required*"}
                />
                {/* </Form.Group> */}
                {/* </Row> */}
              </Form.Group>
            )}
            {["checkbox"].map((type) => (
              <div key={`inlinecustomertarget-${type}`} className="mb-1 mt-3">
                <Form.Check
                  inline
                  label="ENTRASOL"
                  value="is_entrasol"
                  name="is_entrasol"
                  type={type}
                  id="is_entrasol"
                  checked={checkedEntrasol}
                  onClick={(e) => checkboxHandlerConsumer2(e)}
                  onChange={(e) => handleDetailsChange(e)}
                />
              </div>
            ))}
            {checkedEntrasol && (
              <Form.Group>
                <Form.Label className="font-med">Diagnosis:</Form.Label>
                {/* <Row> */}
                {/* <Form.Group> */}
                <textarea
                  value={details?.entrasol_diagnosis}
                  name="entrasol_diagnosis"
                  className="form-control ms-1"
                  onChange={handleDetailsChange}
                  type="text"
                ></textarea>
                <InputError
                  isValid={isError.entrasol_diagnosis}
                  message={"This field is required*"}
                />
                {/* </Form.Group> */}
                {/* </Row> */}
              </Form.Group>
            )}
            {["checkbox"].map((type) => (
              <div key={`inlinecustomertarget-${type}`} className="mb-1 mt-3">
                <Form.Check
                  inline
                  label="DIABETASOL-SWEETENER"
                  value="is_diabetasol_sweetener"
                  name="is_diabetasol_sweetener"
                  type={type}
                  id="is_diabetasol_sweetener"
                  checked={checkedDiabetasolSweetener}
                  onClick={(e) => checkboxHandlerConsumer3(e)}
                  onChange={(e) => handleDetailsChange(e)}
                />
              </div>
            ))}
            {checkedDiabetasolSweetener && (
              <Form.Group>
                <Form.Label className="font-med">Diagnosis:</Form.Label>
                {/* <Row> */}
                {/* <Form.Group> */}
                <textarea
                  value={details?.diabetasol_sweetener_diagnosis}
                  name="diabetasol_sweetener_diagnosis"
                  className="form-control ms-1"
                  onChange={handleDetailsChange}
                  type="text"
                ></textarea>
                <InputError
                  isValid={isError.diabetasol_sweetener_diagnosis}
                  message={"This field is required*"}
                />
                {/* </Form.Group> */}
                {/* </Row> */}
              </Form.Group>
            )}
          </Form.Group>
        </Form>

        <Form style={{ textAlign: "left" }}>
          {user_type === "consumer" && (
            <>
              <>
                <Form.Group className="mb-3" controlId="formGroupStoreName">
                  <Form.Label className="font-med">
                    Best Time to Call <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Select
                    name="call_time"
                    type="dropdown"
                    value={details?.call_time}
                    onChange={handleDetailsChange}
                  >
                    <option>Select...</option>
                    <option value="between 8am to 10 am">
                      between 8am to 10 am
                    </option>
                    <option value="10am to 12nn">10am to 12nn</option>
                    <option value="anytime in the morning">
                      anytime in the morning
                    </option>
                    <option value="1pm to 3pm">1pm to 3pm</option>
                    <option value="3pm to 6pm<">3pm to 6pm</option>
                    <option value="early afternoon">early afternoon</option>
                    <option value="late afternoon">late afternoon</option>
                    <option value="anytime in the afternoon">
                      anytime in the afternoon
                    </option>
                    <option value="anytime">anytime</option>
                    <option value="no time provided">no time provided</option>
                  </Form.Select>
                  <InputError
                    isValid={isError.call_time}
                    message={"This field is required*"}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupStoreName">
                  <Form.Label className="font-med">
                    BUMO <span className="required-badge">*</span>
                  </Form.Label>
                  <Form.Control
                    name="bumo"
                    type="text"
                    value={details?.bumo}
                    onChange={handleDetailsChange}
                  />
                  <InputError
                    isValid={isError.bumo}
                    message={"This field is required*"}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupStoreName">
                  <Form.Label className="font-med">
                    LC/NC/PTS <span className="required-badge">*</span>
                  </Form.Label>
                  {["radio"].map((type) => (
                    <div key={`inlinelc-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="LC"
                        value="LC"
                        name="lc_nc_p"
                        type={type}
                        id={`inlinelc-${type}-1`}
                        onClick={handleDetailsChange}
                        checked={details?.lc_nc_p === "LC"}
                      />
                      <Form.Check
                        inline
                        label="NC"
                        value="NC"
                        name="lc_nc_p"
                        type={type}
                        id={`inlinelc-${type}-2`}
                        onClick={handleDetailsChange}
                        checked={details?.lc_nc_p === "NC"}
                      />
                      <Form.Check
                        inline
                        label="PTS"
                        value="PTS"
                        name="lc_nc_p"
                        type={type}
                        id={`inlinelc-${type}-3`}
                        onClick={handleDetailsChange}
                        checked={details?.lc_nc_p === "PTS"}
                      />
                    </div>
                  ))}
                  <InputError
                    isValid={isError.lc_nc_p}
                    message={"This field is required*"}
                  />
                  {/* <Form.Control name="type_1" type="text" value={details.type_1} onChange={handleDetailsChange}/> */}
                </Form.Group>
              </>

              <Form.Group className="mb-3" controlId="formGroupRemarks">
                <Form.Label className="font-med">
                  EU/CG <span className="required-badge">*</span>
                </Form.Label>
                {["radio"].map((type) => (
                  <div key={`inlineeu-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="EU"
                      value="EU"
                      name="eu_cg"
                      type={type}
                      id={`inlineeu-${type}-1`}
                      onClick={handleDetailsChange}
                      checked={details?.eu_cg === "EU"}
                    />
                    <Form.Check
                      inline
                      label="CG"
                      value="CG"
                      name="eu_cg"
                      type={type}
                      id={`inlineeu-${type}-2`}
                      onClick={handleDetailsChange}
                      checked={details?.eu_cg === "CG"}
                    />
                  </div>
                ))}
                <InputError
                  isValid={isError.eu_cg}
                  message={"This field is required*"}
                />
              </Form.Group>
            </>
          )}
          {
            <>
              {user_type === "patient" && (
                <>
                  <Form.Group className="mb-3" controlId="formGroupStoreName">
                    <Form.Label className="font-med">
                      Best Time to Call{" "}
                      <span className="required-badge">*</span>
                    </Form.Label>
                    <Form.Select
                      name="call_time"
                      type="dropdown"
                      value={details?.call_time}
                      onChange={handleDetailsChange}
                    >
                      <option>Select...</option>
                      <option>between 8am to 10 am</option>
                      <option>10am to 12nn</option>
                      <option>anytime in the morning</option>
                      <option>1pm to 3pm</option>
                      <option>3pm to 6pm</option>
                      <option>early afternoon</option>
                      <option>late afternoon</option>
                      <option>anytime in the afternoon</option>
                      <option>anytime</option>
                      <option>no time provided</option>
                    </Form.Select>
                    <InputError
                      isValid={isError.call_time}
                      message={"This field is required*"}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupStoreName">
                    <Form.Label className="font-med">
                      Patient Type <span className="required-badge">*</span>
                    </Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="IN-PATIENT"
                          value="IN-PATIENT"
                          name="patient_type"
                          type={type}
                          id={`inline-${type}-1`}
                          onClick={handleDetailsChange}
                          checked={details?.patient_type === "IN-PATIENT"}
                        />
                        <Form.Check
                          inline
                          label="OUT-PATIENT"
                          value="OUT-PATIENT"
                          name="patient_type"
                          type={type}
                          id={`inline-${type}-2`}
                          onClick={handleDetailsChange}
                          checked={details?.patient_type === "OUT-PATIENT"}
                        />
                      </div>
                    ))}
                    <InputError
                      isValid={isError.patient_type}
                      message={"This field is required*"}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupStoreName">
                    <Form.Label className="font-med">
                      LC/NC/PTS <span className="required-badge">*</span>
                    </Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inlinelc-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="LC"
                          value="LC"
                          name="lc_nc_p"
                          type={type}
                          id={`inlinelc-${type}-1`}
                          onClick={handleDetailsChange}
                          checked={details?.lc_nc_p === "LC"}
                        />
                        <Form.Check
                          inline
                          label="NC"
                          value="NC"
                          name="lc_nc_p"
                          type={type}
                          id={`inlinelc-${type}-2`}
                          onClick={handleDetailsChange}
                          checked={details?.lc_nc_p === "NC"}
                        />
                        <Form.Check
                          inline
                          label="PTS"
                          value="PTS"
                          name="lc_nc_p"
                          type={type}
                          id={`inlinelc-${type}-3`}
                          onClick={handleDetailsChange}
                          checked={details?.lc_nc_p === "PTS"}
                        />
                      </div>
                    ))}
                    <InputError
                      isValid={isError.lc_nc_p}
                      message={"This field is required*"}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupRemarks">
                    <Form.Label className="font-med">
                      EU/CG <span className="required-badge">*</span>
                    </Form.Label>
                    {["radio"].map((type) => (
                      <div key={`inlineeu-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="EU"
                          value="EU"
                          name="eu_cg"
                          type={type}
                          id={`inlineeu-${type}-1`}
                          onClick={handleDetailsChange}
                          checked={details?.eu_cg === "EU"}
                        />
                        <Form.Check
                          inline
                          label="CG"
                          value="CG"
                          name="eu_cg"
                          type={type}
                          id={`inlineeu-${type}-2`}
                          onClick={handleDetailsChange}
                          checked={details?.eu_cg === "CG"}
                        />
                      </div>
                    ))}
                    <InputError
                      isValid={isError.eu_cg}
                      message={"This field is required*"}
                    />
                  </Form.Group>
                </>
              )}
            </>
          }
          {/* <hr></hr> */}
          {/* <Form.Group style={{textAlign:"left"}} className="mb-3 mt-3" controlId="formGroupStoreName">
            {['checkbox'].map((type) => (
                <div key={`inlinelc-${type}`} className="mb-3">
                <Form.Check
                    inline
                    label="I agree to the terms and conditions provided by Kalbe International. Click link to view"
                    value="PRIVACY-POLICY"
                    name="privacy_policy"
                    type={type}
                    id={`inlinelc-${type}-1`}
                    onClick={handleDetailsChange}
                />
                <Form.Label ><a target="_blank" href="https://www.termsandconditions.com">Terms and Conditions</a>.</Form.Label>
                </div>
            ))}
            <InputError isValid={isError.privacy_policy} message={"This field is required*"}/>
                <Form.Contr/ol name="type_1" type="text" value={details.type_1} onChange={handleDetailsChange}/>
            </Form.Group> */}
          {(primaryDetails.activation_type !== "" ||
            primaryDetails.classification_type !== "") && (
            <Form.Group className="mt-3 mb-3" controlId="formGroupSubmit">
              {loading ? (
                <Row className="justify-content-md-center">
                  <Col md={1} sm={1} xs={1} className="left-38">
                    <ReactLoading
                      type="spinningBubbles"
                      color="#87BD40"
                      height={100}
                      width={50}
                    />
                  </Col>
                </Row>
              ) : (
                <Button
                  variant="primary"
                  size="md"
                  disabled={!checked}
                  className="full-width-btn confirm-btn"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </Form.Group>
          )}
        </Form>
        <Col xs={12} md={12} className="mt-3">
          <img
            src={diabetasol}
            alt="logo"
            width={130}
            height={60}
            style={{ borderRadius: "10px" }}
          />{" "}
          <img
            src={entrasol}
            alt="logo"
            width={130}
            height={60}
            style={{ borderRadius: "10px" }}
          />
        </Col>
        <Col xs={12} md={12} className="mt-1">
          <img
            src={logo}
            alt="logo"
            width={100}
            height={50}
            style={{ borderRadius: "10px" }}
          />
        </Col>
      </Row>
      <img src={swoosh} alt="logo" height={300} className={"swoosh"} />
    </Container>
  );
}

export default Consumer;
